<template>
  <UForm
    ref="form"
    :schema="schema"
    :state="state"
    class="space-y-4"
    @submit="(event: FormValue) => $emit('submit', event.data, form)"
  >
    <FormFieldBase v-model="state" />
    <slot name="footer" />
  </UForm>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import type { FormSubmitEvent } from '#ui/types'
import type { Field } from '#field/types'

type FormValue = FormSubmitEvent<z.output<typeof schema>>

const props = defineProps({
  initialValue: {
    type: Object as PropType<Partial<Field>>,
  },
})

defineEmits<{
  (e: 'submit', event: z.output<typeof schema>, form: Ref): void
}>()

const schema = z.object({
  name: z.string().trim().min(1).max(16),
})

const form = ref()
const state = reactive({
  name: '',
  description: '',
  default: '',
  ...props.initialValue,
})
</script>
